import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo"
import CTA from '../components/cta';
import ToolsWeUse from '../components/tools-we-use';

import hireDeveloperScaled from '../images/backrounds/hire-a-developer-scaled.jpg';

const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Development Services"
      description="729 Solutions developers are well versed many programming languages for front and back end development projects both large and small."
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${hireDeveloperScaled})`,
          backgroundPosition: 'top'
        }}
      >
        <div className="hero-title bg-primary-transparent">
          <h1 className="text-white">
            Development Services
          </h1>
          <p className="text-white">
            Great design drives excellent development. For 15 years we have been committed to building quality software products. We are trusted by some of the biggest names in Tech because we work hard to understand the real purpose and goals of our customers. Our multi-stack problem-solving engineers can handle almost any challenge.
          </p>
          <CTA href="/contact-us/" text="TELL US ABOUT YOUR PROJECT" className="btn btn-secondary-light"/>
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>Development Services at a Glance</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
              <svg
              className="text-white mb-3"
                height={150}
                viewBox="0 0 144 144"
                width={150}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.29,87.93H21.85a7.47,7.47,0,0,1-7.44-7.45V36.66a7.47,7.47,0,0,1,7.44-7.45H49.58"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <path
                  d="M105.71,29.21h16.44a7.47,7.47,0,0,1,7.44,7.45V80.48a7.47,7.47,0,0,1-7.44,7.45h-10"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <path
                  d="M38.66,81.37H36a5.6,5.6,0,0,1-5.58-5.58V41.34A5.59,5.59,0,0,1,36,35.77h13.8"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
                <path
                  d="M105.35,35.77a5.58,5.58,0,0,1,5.57,5.57V75.79a5.59,5.59,0,0,1-5.57,5.58H63.5"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
                <line
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  x1="22.55"
                  x2="22.55"
                  y1="52.44"
                  y2="63.55"
                />
                <circle
                  cx="119.89"
                  cy="57.99"
                  fill="none"
                  r="4.41"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
                <rect
                  fill="none"
                  height="25.23"
                  rx="5"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  width="10.01"
                  x="54.49"
                  y="22.32"
                />
                <rect
                  fill="none"
                  height="25.23"
                  rx="5"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  width="10.01"
                  x="66.73"
                  y="22.32"
                />
                <rect
                  fill="none"
                  height="25.23"
                  rx="5"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  width="10.01"
                  x="78.97"
                  y="22.32"
                />
                <rect
                  fill="none"
                  height="25.23"
                  rx="5"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  width="10.01"
                  x="91.21"
                  y="22.32"
                />
                <path
                  d="M60.12,110.48,43.35,80.64s-4-5.91-4.17-10.1,6.69-4.4,11.54,1.84S63.15,87.79,63.15,87.79H106.4s5.26,18.52-7.7,28.4S68.58,121.68,60.12,110.48Z"
                  fill="none"
                  stroke="currentColor"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
              </svg>
              <h3>Mobile Application Engineering and Development</h3>
              <p className="text-white">Develop powerful, highly usable mobile apps that solve business problems, attract users, and reinforce your brand. We build native, hybrid, and cross-platform apps that run on all major operating systems such as iOS and Android.</p>
          </div>
          <div className="col-lg-4">
            <svg
              className="text-white mb-3"
              height={150}
              viewBox="0 0 144 144"
              width={150}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M72,23.15c16.49,0,33,.13,49.47-.07C127,23,129,25,129,30.5c-.19,20.3-.07,42.5-.07,62.8,0,5.18-1.25,6.48-6.52,6.49-11.41,0-22.81,0-34.22,0-3.47,0-3.68.22-3.69,3.68,0,2.58.05,3.26,0,5.84,0,2,.85,2.82,2.86,2.81,4.41,0,8.83-.06,13.23.15,2.9.14,4.07,1.63,4,4.36a4.18,4.18,0,0,1-4.3,4.23c-3.41.09-6.83,0-10.24,0q-22.74,0-45.47,0c-3.2,0-5-1.54-5.06-4.24s1-4.19,3.7-4.34c4.24-.23,8.49-.16,12.73-.21,3.34,0,3.59-.27,3.61-3.52,0-2.41,0-2.93,0-5.34,0-3.14-.31-3.45-3.42-3.45h-33c-1,0-2,0-3,0-3.61-.25-5-1.77-5-5.43C15,73.1,15,50,15,28.77c0-3.78,1.84-5.6,5.7-5.61Q46.35,23.13,72,23.15ZM72,80.4h46.21c3.52,0,3.84-.32,3.84-3.86,0-17.8,0-24.68,0-42.48,0-3.53-.31-3.84-3.86-3.84H25.75c-3.55,0-3.85.3-3.85,3.85V76.55c0,3.54.33,3.85,3.86,3.85Z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </svg>
            <h3>Web Application Engineering and Development</h3>
            <p className="text-white">
              Our software engineers have a wealth of experience in building web applications. We are also experts in many verticals and business domains, including e-Commerce, e-Learning, AdTech, Finance, Entertainment, and more.              
            </p>
          </div>
        <div className="col-lg-4">
           <svg
              className="text-white mb-3"
              height={150}
              viewBox="0 0 144 144"
              width={150}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M72,19.43c17.75,0,35.49.14,53.23-.08,6-.08,8.18,2.08,8.13,8-.21,21.84-.09,43.69-.08,65.54,0,5.58-1.35,7-7,7-12.28,0-24.55,0-36.83,0-3.73,0-4,.23-4,4,0,2.77,0,5.55,0,8.32-.06,2.19.91,3,3.07,3,4.75,0,9.5-.06,14.24.16,3.12.15,4.38,1.76,4.28,4.69a4.51,4.51,0,0,1-4.63,4.56c-3.67.09-7.35,0-11,0q-24.47,0-48.93,0c-3.45,0-5.34-1.66-5.46-4.57s1.09-4.51,4-4.66c4.56-.25,9.13-.18,13.7-.23,3.59,0,3.87-.29,3.89-3.79q0-3.9,0-7.79c0-3.38-.34-3.71-3.69-3.71H19.34c-1.07,0-2.15,0-3.22-.05-3.88-.27-5.42-1.9-5.42-5.84q0-34.26,0-68.5c0-4.08,2-6,6.13-6Q44.4,19.41,72,19.43Zm0,73.15q24.87,0,49.73,0c3.79,0,4.14-.34,4.14-4.15V31c0-3.8-.33-4.13-4.15-4.13q-49.74,0-99.47,0c-3.82,0-4.14.33-4.14,4.15V88.44c0,3.81.35,4.14,4.16,4.14Q47.1,92.6,72,92.58Z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <path
                d="M34.34,59.69,56.49,48.28a1.68,1.68,0,0,1,2.45,1.5h0a1.68,1.68,0,0,1-.93,1.5L37.44,61.59v.11L58,72a1.69,1.69,0,0,1,.93,1.51h0A1.68,1.68,0,0,1,56.49,75L34.34,63.6a1.67,1.67,0,0,1-.91-1.49v-.92A1.69,1.69,0,0,1,34.34,59.69Z"
                fill="currentColor"
              />
              <path
                d="M63.39,76.13l14-36.5A1.68,1.68,0,0,1,79,38.55h.18a1.68,1.68,0,0,1,1.57,2.29L66.62,77.34a1.7,1.7,0,0,1-1.57,1.08H65A1.69,1.69,0,0,1,63.39,76.13Z"
                fill="currentColor"
              />
              <path
                d="M109.66,63.66,87.51,75a1.68,1.68,0,0,1-2.45-1.5h0A1.69,1.69,0,0,1,86,72L106.72,61.7v-.11L86,51.28a1.68,1.68,0,0,1-.93-1.5h0a1.68,1.68,0,0,1,2.44-1.5l22.15,11.31a1.68,1.68,0,0,1,.92,1.5v1.08A1.69,1.69,0,0,1,109.66,63.66Z"
                fill="currentColor"
              />
            </svg>
            <h3>Website Development</h3>
            <p className="text-white">
              Our web development services include extensive web application design and development, database development, e-Commerce development, and social medial system development. We provide our customers with a sustainable competitive advantage by offering cost-effective solutions.           
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <svg
              className="text-white mb-3"
              height={150}
              viewBox="0 0 144 144"
              width={150}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                data-name="decision-process"
                id="bb552430-d69a-4fd7-ba83-c711943bed30"
              >
                <path
                  d="M13,34.33l28.4-15.07L69.82,34.33,41.41,49.4Z"
                  data-name="Path 391"
                  fill="none"
                  id="e94deb70-e878-4163-9a44-32b0ad1cc793"
                  stroke="currentColor"
                  strokeLinecap="square"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
                <rect
                  data-name="Rectangle 45"
                  fill="none"
                  height="26.37"
                  id="f4bca829-a29b-40e5-9efe-62592434050c"
                  stroke="currentColor"
                  strokeLinecap="square"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  width="48.07"
                  x="82.92"
                  y="58.82"
                />
                <path
                  d="M30.49,98.37H52.34c8.44,0,15.29,5.9,15.29,13.18h0c0,7.28-6.85,13.19-15.29,13.19H30.49c-8.45,0-15.3-5.91-15.3-13.19h0C15.19,104.27,22,98.37,30.49,98.37Z"
                  data-name="Rectangle 46"
                  fill="none"
                  id="e29e77b7-1d37-4d1d-b73a-460d15d108a5"
                  stroke="currentColor"
                  strokeLinecap="square"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
                <line
                  data-name="Line 45"
                  fill="none"
                  id="ab3e197e-895d-419e-9cd2-d99b018481f3"
                  stroke="currentColor"
                  strokeLinecap="square"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  x1="41.41"
                  x2="41.41"
                  y1="49.4"
                  y2="98.37"
                />
                <path
                  d="M69.82,34.33H107V47.52"
                  data-name="Path 392"
                  fill="none"
                  id="b1922e3f-8c9b-42a0-8218-e962b5c794bf"
                  stroke="currentColor"
                  strokeLinecap="square"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
                <path
                  d="M67.63,111.55H107V96.48"
                  data-name="Path 393"
                  fill="none"
                  id="b732c7f6-6467-4deb-a50a-38f9d2edef05"
                  stroke="currentColor"
                  strokeLinecap="square"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
              </g>
            </svg>
            <h3>Application Program Interface (API) Development</h3>
            <p className="text-white">
              729 Solutions knows that integrations allowing your various tools to talk to each other is crucial. We can help make that happen by developing custom APIs (Application Program Interface) and building real-time data sync systems for almost anything you can think of!
            </p>
          </div>
          <div className="col-lg-4">
             <svg
              className="text-white mb-3"
              height={150}
              viewBox="0 0 144 144"
              width={150}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M84.35,20.16c1.5,0,3,0,4.5,0,3.3.07,5.47,2,5.65,5.37.15,2.74,0,5.49.08,8.23.07,4.8,1.8,5.78,6,3.44,3.68-2,7.31-1,8.83,2.43,1.6,3.68,0,8.12-3.63,9a9,9,0,0,1-5.57-1.06c-3.66-1.9-5.53-1-5.63,3.12-.07,2.57,0,5.15,0,7.73-.09,4.37-2.07,6.33-6.5,6.43-2.49,0-5,0-7.49,0-4.49.13-5.57,2.07-3.32,5.84,1.75,3,1.67,5.44-.23,7.59a7,7,0,0,1-7.83,1.51c-3.45-1.51-4.57-5-2.7-8.53,2.63-4.93,1.76-6.35-4-6.41-2.41,0-4.83.05-7.24,0-3.61-.17-5.69-2.24-5.83-5.84-.1-2.65,0-5.32-.05-8-.07-4.53-1.94-5.6-5.74-3.3-3,1.81-5.18,1.83-7.38,0a6.74,6.74,0,0,1-1.94-7.48c1.22-3.68,4.89-5.11,8.49-3.32,5.42,2.71,6.55,2,6.57-4.23,0-2.24,0-4.49,0-6.73.15-3.79,2.22-5.78,6-5.81,3.16,0,6.32-.06,9.49,0s4.52,2.5,3.06,5.34c-.23.45-.53.85-.78,1.28-1.63,2.82-1.25,5.59,1,7.4a6.55,6.55,0,0,0,8.08-.3c2-1.81,2.14-4.4.48-7.3-2.51-4.36-1.27-6.43,3.84-6.45C81.85,20.15,83.1,20.16,84.35,20.16Z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <path
                d="M38.06,94.22c-.48-1-2.8-4.51-3.39-5.48C32,84.33,29.26,80,26.56,75.57c-2-3.25-1.77-5.78.67-7.47s5.26-.77,7.76,2.81a60.69,60.69,0,0,0,4.5,5.83c3.64,4.07,7.29,8.17,11.29,11.88,6.32,5.87,10.1,12.69,9.38,21.56-.29,3.47-.49,7-.66,10.44-.13,2.72-1.44,3.74-5.28,3a30.52,30.52,0,0,0-11.47-.08c-3.63.71-6.14.19-5.8-2.86.72-6.54-2.3-11.35-6.57-15.9s-7.75-9.34-11.77-13.89a22.09,22.09,0,0,1-5.41-12.6c-.95-8.51-2-17-3-25.5-.27-2.4-.62-4.81,1.67-6.64S17,44.66,18.18,47.6a32.65,32.65,0,0,1,1.69,7c.54,3.7.66,7.46,1.28,11.14.49,2.91.93,6,2.3,8.54C26.5,79.89,30.1,85.2,33.54,90.6A10,10,0,0,0,35,92.5a.9.9,0,0,0,.19.15C35.63,93,37.83,94.36,38.06,94.22Z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <path
                d="M23.86,52a.58.58,0,0,1,.84.14,6.24,6.24,0,0,1,.9,2.12c.6,2.32,1.07,4.67,1.45,7a2.77,2.77,0,0,1-.53,1.94c-.55.68-1.48.91-1.67-.34-.5-3.31-.89-6.63-1.3-10A1.23,1.23,0,0,1,23.86,52Z"
                fill="currentColor"
                stroke="currentColor"
                strokeMiterlimit="10"
              />
              <path
                d="M28.23,55.27c.53.18,1.23,1.4,1.47,2a27.69,27.69,0,0,1,1.18,3.82A2.94,2.94,0,0,1,31,62.6a.77.77,0,0,1-.84.44,1.05,1.05,0,0,1-.87-.82c-.66-2.19-.85-3.19-1.42-5.41C27.72,55.83,28,55.39,28.23,55.27Z"
                fill="currentColor"
                stroke="currentColor"
                strokeMiterlimit="10"
              />
              <path
                d="M105.94,94.22c.48-1,2.8-4.51,3.39-5.48,2.67-4.41,5.41-8.78,8.11-13.17,2-3.25,1.77-5.78-.67-7.47s-5.26-.77-7.76,2.81a60.69,60.69,0,0,1-4.5,5.83c-3.64,4.07-7.29,8.17-11.29,11.88-6.32,5.87-10.1,12.69-9.38,21.56.29,3.47.49,7,.66,10.44.13,2.72,1.44,3.74,5.28,3a30.52,30.52,0,0,1,11.47-.08c3.63.71,6.14.19,5.8-2.86-.72-6.54,2.3-11.35,6.57-15.9s7.75-9.34,11.77-13.89a22.09,22.09,0,0,0,5.41-12.6c.95-8.51,2-17,3-25.5.27-2.4.62-4.81-1.67-6.64s-5.12-1.52-6.27,1.42a32.65,32.65,0,0,0-1.69,7c-.54,3.7-.66,7.46-1.28,11.14-.49,2.91-.93,6-2.3,8.54-3,5.61-6.65,10.92-10.09,16.32a10,10,0,0,1-1.41,1.9.9.9,0,0,1-.19.15C108.37,93,106.17,94.36,105.94,94.22Z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <path
                d="M120.14,52a.58.58,0,0,0-.84.14,6.24,6.24,0,0,0-.9,2.12c-.6,2.32-1.07,4.67-1.45,7a2.77,2.77,0,0,0,.53,1.94c.55.68,1.48.91,1.67-.34.5-3.31.89-6.63,1.3-10A1.23,1.23,0,0,0,120.14,52Z"
                fill="currentColor"
                stroke="currentColor"
                strokeMiterlimit="10"
              />
              <path
                d="M115.77,55.27c-.53.18-1.23,1.4-1.47,2a27.69,27.69,0,0,0-1.18,3.82,2.94,2.94,0,0,0-.11,1.5.77.77,0,0,0,.84.44,1.05,1.05,0,0,0,.87-.82c.66-2.19.85-3.19,1.42-5.41C116.28,55.83,116,55.39,115.77,55.27Z"
                fill="currentColor"
                stroke="currentColor"
                strokeMiterlimit="10"
              />
            </svg>
            <h3>Custom Integrations</h3>
            <p className="text-white">
              729 Solutions can also build completely custom applications to connect any other system with an API or even help you build an API if needed. We can help you integrate with Salesforce, JIRA, Quickbooks, and hundreds of other tools.
            </p>
          </div>
          <div className="col-lg-4">
            <svg
              className="text-white mb-3"
              height={150}
              viewBox="0 0 144 144"
              width={150}
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                fill="none"
                height="38.33"
                rx="6.86"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
                width="38.33"
                x="52.84"
                y="14.69"
              />
              <rect
                fill="none"
                height="23.64"
                rx="4.23"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
                width="23.64"
                x="21.48"
                y="105.67"
              />
              <rect
                fill="none"
                height="23.64"
                rx="4.23"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
                width="23.64"
                x="60.18"
                y="105.67"
              />
              <rect
                fill="none"
                height="23.64"
                rx="4.23"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="3"
                width="23.64"
                x="98.88"
                y="105.67"
              />
              <path
                d="M32.53,97.73V88.4a9.87,9.87,0,0,1,9.84-9.84h59.26a9.87,9.87,0,0,1,9.84,9.84v9.33"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <line
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
                x1="72"
                x2="72"
                y1="97.73"
                y2="59.77"
              />
            </svg>
            <h3>Agile development using the latest development languages and techniques</h3>
            <p className="text-white">
              We use Velocity Based Agile development, allowing the process to tell us what we can produce in a particular time frame, allowing time, cost and scope to be adjusted accordingly. Our Developers use the latest languages and techniques, with each project reviewed individually.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <CTA href="/contact-us/" text="HOW CAN WE HELP?" className="btn btn-primary"/>
          </div>
        </div>
      </div>
    </section>

    <ToolsWeUse />
      
    <section
      className="d-flex bg-pattern-trihex"
      style={{
        backgroundSize: '650px'
      }}
    >
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>We’re ready to help</h2>
            <p>
              729 Solutions is armed with a plethora of top-notch coders ready to attack your project. Let’s get together for a quick chat about your project so that we can build a plan of action and begin marching towards success.
            </p>
            <CTA href="/contact-us/" text="LET'S TALK" className="btn btn-primary"/>
          </div>
        </div>
      </div>
    </section>

  </Layout>
  );

  export default Index;
