import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ToolsWeUse = () => (
  <section>
    <div className="container pt-6">
      <div className="row">
        <div className="col">
          <h2>Some Of The Tools We Use</h2>
        </div>
      </div>
    </div>
    <div className="container-fluid text-center mb-6">
      <div className="row mb-3">
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/html5.png" alt="HTML 5" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/css3.png" alt="CSS 3" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/js.png" alt="Java Script" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/bootstrap.png" alt="Bootstrap" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/jquery.png" alt="Jquery" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/react.png" alt="React" />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/ruby.png" alt="Ruby" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/php.png" alt="PHP" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/java.png" alt="Java" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/dotnet.png" alt="Microsoft .NET" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/csharp.png" alt="C#" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/android.png" alt="Android" />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/asp.png" alt="ASP.NET" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/groovy.png" alt="Groovy" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/ios.png" alt="iOS" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/swift.png" alt="Swift" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/objc.png" alt="Objective-C" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/rails.png" alt="Rails" />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/wordpress.png" alt="Wordpress" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/azure.png" alt="Azure" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/node.png" alt="Node Js" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/ext.png" alt="Ext JS" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/drupal.png" alt="Drupal" />
        </div>
        <div className="col-4 col-sm-2 pb-2">
          <StaticImage src="../images/tools/aws.png" alt="AWS" />
        </div>
      </div>
    </div>
  </section>
);

export default ToolsWeUse;
